import { useMemo } from 'react';
import _ from 'lodash';
import { useProvinces } from 'api/provinceApi';
import { StyledSelect } from 'styles/overrides';
import { filterOptions } from 'utils/formatUtils';

export default function StoreSelector({
  provinceId,
  onChange,
  value,
  ...restProps
}) {
  const { data: provinces, isLoading } = useProvinces({
    options: {
      select: (response) => response?.data ?? [],
      enabled: !!provinceId,
    },
  });

  const mappingStores = useMemo(() => {
    return _.reduce(
      provinces,
      (mapping, currentProvince) => {
        mapping[currentProvince.id] = currentProvince.stores ?? [];

        return mapping;
      },
      {},
    );
  }, [provinces]);

  const options = useMemo(() => {
    return _.map(mappingStores[provinceId], ({ id, name }) => ({
      label: name,
      value: id.toString(),
    }));
  }, [mappingStores, provinceId]);

  return (
    <StyledSelect
      placeholder="Chọn cửa hàng"
      options={options}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
