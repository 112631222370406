import { useMemo } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import AnswerForm from '../AnswerForm';
import { useCreateAnswer, useUpdateAnswer } from 'api/answerApi';

const normalizeInitialValues = (initialValues) => {
  if (!initialValues) {
    return initialValues;
  }

  const { name, index, question } = initialValues;

  return {
    name,
    index,
    question_id: question?.id,
  };
};

export default function AnswerModal({ initialValue, onSuccess, ...props }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createMutation = useCreateAnswer();
  const updateMutation = useUpdateAnswer(initialValue?.id);
  const mutation = useMemo(() => {
    return initialValue ? updateMutation : createMutation;
  }, [initialValue, updateMutation, createMutation]);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const handleSubmitData = (values) => {
    mutation.mutate(values, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValue ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: () => {
        Notification(
          'error',
          intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValue ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form.validateFields().then(handleSubmitData);
      }}
      okButtonProps={{
        loading: mutation.isLoading,
      }}
      {...props}
    >
      <AnswerForm
        form={form}
        initialValues={normalizeInitialValues(initialValue)}
      />
    </Modal>
  );
}
