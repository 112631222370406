import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledSwitch,
} from 'styles/overrides';
import { ProvinceSelector } from 'components/selector';

export const StoreForm = ({ initialValues, ...props }) => {
  const intl = useIntl();

  useEffect(() => {
    props.form?.setFieldsValue(initialValues);
  }, [initialValues, props.form]);

  return (
    <StyledForm
      layout="vertical"
      initialValues={{
        active: true,
      }}
      size="large"
      {...props}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput
              placeholder={intl.formatMessage({
                id: 'views.stores.form.placeholder.name',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.provinceId',
            })}
            name="province_id"
            rules={[{ required: true }]}
          >
            <ProvinceSelector
              placeholder={intl.formatMessage({
                id: 'common.placeholder.provinceId',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.active',
            })}
            name="active"
            valuePropName="checked"
          >
            <StyledSwitch />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
};
