/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'components/table';
import { StyledActions, StyledButton } from 'styles/overrides';
import { useQuestions, useDeleteMultipleQuestions } from 'api/questionApi';
import { Notification } from 'components/Notification';
import QuestionModal from '../QuestionModal';
import { Modal } from 'antd';
import _ from 'lodash';

export default function QuestionTable() {
  const intl = useIntl();
  const { data, isLoading, refetch } = useQuestions();
  const deleteMultipleMutation = useDeleteMultipleQuestions();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.questions.table.answers' }),
      dataIndex: 'answers',
      render: (value) =>
        _.map(value, (item) => <p key={item.id}>- {item.name}</p>),
    },
    {
      title: intl.formatMessage({ id: 'common.table.index' }),
      dataIndex: 'index',
    },
  ];

  const handleMultipleDelete = () => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMultipleMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            refetch();
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setSelectedValue(null);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError() {
            Notification(
              'error',
              intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  return (
    <>
      <StyledActions>
        <StyledButton type="primary" onClick={() => setIsOpenModal(true)}>
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>

        <StyledButton
          disabled={selectedRowKeys.length === 0}
          onClick={handleMultipleDelete}
          type="danger"
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data || []}
        loading={isLoading}
        pagination={false}
      />

      <QuestionModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValue={selectedValue}
      />
    </>
  );
}
