import { useSelector } from 'react-redux';
import { APP_STATUS } from 'constants/status';
import { getCurrentStatus } from 'store/slices/appSlice';
import { LoginForm } from './components/LoginForm';
import { LocationForm } from './components/LocationForm';
import { OrderInfoForm } from './components/OrderInfoForm';
import { ChooseGifts } from './components/ChooseGifts';
import { QuestionForm } from './components';

export default function AppView() {
  const status = useSelector(getCurrentStatus);

  const renderApp = () => {
    if (status === APP_STATUS.LOGIN) {
      return <LoginForm />;
    }

    if (status === APP_STATUS.LOCATION) {
      return <LocationForm />;
    }

    if (status === APP_STATUS.ORDER_INFO) {
      return <OrderInfoForm />;
    }

    if (status === APP_STATUS.CHOOSE_GIFTS) {
      return <ChooseGifts />;
    }

    if (status === APP_STATUS.QUESTIONS) {
      return <QuestionForm />;
    }

    return null;
  };

  return <>{renderApp()}</>;
}
