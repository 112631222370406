import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, Select } from 'antd';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { AppWrapper } from 'layout/components';
import {
  StyledFormItem,
  StyledButton,
  StyledSpace,
  StyledSelect,
} from 'styles/overrides';
import { doUpdateData, getCurrentData } from 'store/slices/appSlice';
import { useProvinces } from 'api/appApi';
import { BackButton } from './BackButton';

export const LocationForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentData = useSelector(getCurrentData);
  const { data: provinces = [], isLoading: isProvincesLoading } = useProvinces({
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  const mappingProvinces = useMemo(() => {
    return _.reduce(
      provinces,
      (mapping, currentProvince) => {
        mapping[currentProvince.id] = currentProvince;

        return mapping;
      },
      {},
    );
  }, [provinces]);

  const handleSubmit = (values) => {
    dispatch(doUpdateData({ data: values, redirect: APP_STATUS.ORDER_INFO }));
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues[FIELD.PROVINCE_ID]) {
      form.setFieldsValue({
        [FIELD.STORE_ID]: undefined,
      });
    }
  };

  return (
    <AppWrapper>
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        initialValues={{
          [FIELD.PROVINCE_ID]: currentData[FIELD.PROVINCE_ID],
          [FIELD.STORE_ID]: currentData[FIELD.STORE_ID],
        }}
        layout="vertical"
      >
        <StyledFormItem
          name={FIELD.PROVINCE_ID}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'validate.required' }),
            },
          ]}
          label={intl.formatMessage({ id: 'common.field.province' })}
          style={{ marginBottom: 50 }}
          className="customLabel"
        >
          <StyledSelect loading={isProvincesLoading} $customType="app">
            {provinces.length > 0 &&
              provinces.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
          </StyledSelect>
        </StyledFormItem>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues[FIELD.PROVINCE_ID] !== currentValues[FIELD.PROVINCE_ID]
          }
        >
          {({ getFieldValue }) => {
            const provinceId = getFieldValue(FIELD.PROVINCE_ID);
            const stores =
              _.get(mappingProvinces, [provinceId, 'stores']) ?? [];

            return (
              <StyledFormItem
                name={FIELD.STORE_ID}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'validate.required' }),
                  },
                ]}
                label={intl.formatMessage({ id: 'common.field.store' })}
                style={{ marginBottom: 50 }}
                className="customLabel"
              >
                <StyledSelect $customType="app">
                  {stores.length > 0 &&
                    stores.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </StyledFormItem>
            );
          }}
        </StyledFormItem>

        <StyledFormItem style={{ marginBottom: 0 }}>
          <StyledSpace style={{ justifyContent: 'space-between' }}>
            <BackButton />

            <StyledButton htmlType="submit" type="app">
              {intl.formatMessage({ id: 'common.btn.confirm' })}
            </StyledButton>
          </StyledSpace>
        </StyledFormItem>
      </Form>
    </AppWrapper>
  );
};
