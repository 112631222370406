import styled from 'styled-components';
import logoImg from 'assets/images/logo.png';

const StyledAppHeader = styled.div`
  text-align: center;
  padding: 16px 8px;

  > img {
    max-width: 200px;
    height: auto;
  }
`;

export default function AppHeader() {
  return (
    <StyledAppHeader>
      <img src={logoImg} alt="" />
    </StyledAppHeader>
  );
}
