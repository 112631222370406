import { useIntl } from 'react-intl';
import _ from 'lodash';
import { PAYMENT_STATUS } from 'constants/status';
import { Select } from 'antd';
import { StyledSelect } from 'styles/overrides';

export const PaymentStatusSelector = ({ onChange, value, ...restProps }) => {
  const intl = useIntl();

  return (
    <StyledSelect {...restProps} onChange={onChange} value={value}>
      {_.values(PAYMENT_STATUS).map((status) => (
        <Select.Option key={status} value={status}>
          {intl.formatMessage({ id: `common.status.paymentStatus.${status}` })}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
