import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useProvinces } from 'api/provinceApi';
import { filterOptions } from 'utils/formatUtils';

export default function ProvinceSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useProvinces({
    options: {
      select: (response) =>
        _.map(response?.data, ({ id, name }) => ({
          label: `${name}`,
          value: id.toString(),
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      placeholder="Chọn Tỉnh/Thành"
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
