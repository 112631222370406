export const FIELD = {
  PG_NAME: 'pg_name',
  PG_PHONE_NUMBER: 'pg_phone',
  PROVINCE_ID: 'province_id',
  STORE_ID: 'store_id',
  NAME: 'name',
  PHONE_NUMBER: 'phone',
  TOTAL: 'invoice_value',
  PRODUCTS: 'products',
  GIFTS: 'gifts',
  QUESTIONS: 'questions',
};
