import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer } from 'antd';
import { QuestionOutlined, FormOutlined } from '@ant-design/icons';

import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import {
  StyledLayout,
  StyledLayoutContentWrapper,
  StyledLayoutContent,
} from 'styles/overrides';
import {
  PieChartIcon,
  InboxIcon,
  LayoutIcon,
  PeopleIcon,
  LogIcon,
  SettingIcon,
  HomeIcon,
} from 'assets/icons';
import { getCurrentUser } from 'store/slices/authSlice';
import { Sider } from './components/Sider';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { filteredMenuItemsByCurrentRole } from './utils/handleMenuItems';

const MENU_ITEMS = [
  {
    url: PATH_NAME.DASHBOARD,
    key: 'dashboard',
    icon: <PieChartIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.CUSTOMERS,
    key: 'customers',
    icon: <PeopleIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.PRODUCTS,
    key: 'products',
    icon: <LayoutIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.GIFTS,
    key: 'gifts',
    icon: <InboxIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.PROVINCES,
    key: 'provinces',
    icon: <SettingIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.STORES,
    key: 'stores',
    icon: <HomeIcon />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.QUESTIONS,
    key: 'questions',
    icon: <QuestionOutlined />,
    roles: [ROLE.ADMIN],
  },
  {
    url: PATH_NAME.ANSWERS,
    key: 'answers',
    icon: <FormOutlined />,
    roles: [ROLE.ADMIN],
  },
];

export default function DashboardLayout() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const screens = Grid.useBreakpoint();

  const filteredMenuItems = useMemo(
    () => filteredMenuItemsByCurrentRole(MENU_ITEMS, currentUser?.role?.name),
    [currentUser],
  );

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      <StyledLayout>
        {screens.lg && (
          <Sider collapsed={isCollapsed} items={filteredMenuItems} />
        )}

        <StyledLayoutContentWrapper collapsed={isCollapsed.toString()}>
          <Header onCollapse={handleToggleCollapse} />
          <StyledLayoutContent>
            <Outlet />
          </StyledLayoutContent>
          {/* <Footer /> */}
        </StyledLayoutContentWrapper>
      </StyledLayout>

      {!screens.lg && (
        <Drawer
          open={isCollapsed}
          closable={false}
          onClose={() => setIsCollapsed(false)}
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: 'auto' }}
          placement="left"
        >
          <Sider items={filteredMenuItems} />
        </Drawer>
      )}
    </>
  );
}
