import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
} from 'styles/overrides';
import { QuestionSelector } from 'components/selectors';

export default function AnswerForm({ initialValues, ...props }) {
  const intl = useIntl();

  useEffect(() => {
    props.form?.setFieldsValue(initialValues);
  }, [props?.form]);

  return (
    <StyledForm layout="vertical" size="large" {...props}>
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.answers.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput
              placeholder={intl.formatMessage({
                id: 'views.answers.form.placeholder.name',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.answers.form.label.questionId',
            })}
            name="question_id"
            rules={[{ required: true }]}
          >
            <QuestionSelector
              placeholder={intl.formatMessage({
                id: 'views.answers.form.placeholder.questionId',
              })}
              disabled={!!initialValues}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'common.field.index',
            })}
            name="index"
          >
            <StyledInputNumber
              placeholder={intl.formatMessage({
                id: 'common.placeholder.index',
              })}
              min={0}
              controls={false}
            />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
