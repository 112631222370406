import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { StyledSelect } from 'styles/overrides';
import { useProvinces } from 'api/appApi';

export const ProvinceSelector = ({ onChange, value, ...restProps }) => {
  const intl = useIntl();
  const { data = [], isLoading } = useProvinces({
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  return (
    <StyledSelect
      placeholder={intl.formatMessage({ id: 'common.placeholder.provinceId' })}
      {...restProps}
      loading={isLoading}
      onChange={onChange}
      value={value}
    >
      {data.length > 0 &&
        data.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
    </StyledSelect>
  );
};
