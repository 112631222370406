import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const provinceApi = {
  getAll(params) {
    return axiosClient.get('/admin/provinces', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/provinces/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/provinces', data);
  },
  update({ id, ...data }) {
    return axiosClient.patch(`/admin/provinces/${id}`, data);
  },
  deleteMultiple(data) {
    return axiosClient.delete('/admin/provinces/delete/multiple', { data });
  },
};

export const useProvinces = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['provinces', 'list', params],
    queryFn: () => provinceApi.getAll(params),
    ...options,
  });
};

export const useCreateProvince = () => {
  return useMutation(provinceApi.create);
};

export const useUpdateProvince = () => {
  return useMutation(provinceApi.update);
};

export const useDeleteMultipleProvinces = () => {
  return useMutation(provinceApi.deleteMultiple);
};
