import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CardWrapper, ContentWrapper } from 'components/layout';
import { StyledSpace } from 'styles/overrides';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { DashboardFilterSearchForm } from './components/DashboardFilterSearchForm';
import { getBreadcrumbs } from 'store/slices/layoutSlice';
import { getFirstTitleFromBreadcrumbs } from 'utils/common';
import { useDashboard } from 'api/dashboardApi';
import { useExportCustomers } from 'api/customerApi';
import { Col, Row, Spin, Statistic } from 'antd';
import { Table } from 'components/table';
import { ExportExcel } from 'components/export';

export default function Dashboard() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const currentBreadcrumbs = useSelector(getBreadcrumbs);
  const title = getFirstTitleFromBreadcrumbs(currentBreadcrumbs);

  const { data, isLoading } = useDashboard({
    params: search,
    options: {
      select: (response) => response?.data ?? null,
    },
  });

  const exportCustomersMutation = useExportCustomers(search);

  return (
    <ContentWrapper title={title}>
      <DashboardFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <div style={{ textAlign: 'right', marginBottom: 24 }}>
        <ExportExcel
          mutation={exportCustomersMutation}
          onSuccess={(file) => {
            window.location.href = file;
          }}
        />
      </div>

      <Spin spinning={isLoading}>
        <StyledSpace direction="vertical" size={32}>
          <CardWrapper>
            <Row gutter={16}>
              <Col span={8}>
                <Statistic
                  title="Tổng số data ghi nhận"
                  value={data?.total_records_data ?? 0}
                />
              </Col>

              <Col span={8}>
                <Statistic title="Doanh thu" value={data?.revenue ?? 0} />
              </Col>

              <Col span={8}>
                <Statistic
                  title="Tổng số quà đã tặng"
                  value={data?.total_gifts_given ?? 0}
                />
              </Col>
            </Row>
          </CardWrapper>

          <Row gutter={16}>
            <Col span={8}>
              <Table
                rowKey="bill"
                dataSource={data?.total_gifts_bill ?? []}
                columns={[
                  {
                    title: intl.formatMessage({
                      id: 'views.dashboard.table.bill',
                    }),
                    dataIndex: 'bill',
                  },
                  {
                    title: intl.formatMessage({
                      id: 'views.dashboard.table.quantity',
                    }),
                    dataIndex: 'total',
                  },
                ]}
                pagination={false}
                title={() => 'Quà tặng theo bill'}
              />
            </Col>
            <Col span={8}>
              <Table
                rowKey="name"
                columns={[
                  {
                    title: intl.formatMessage({
                      id: 'views.products.table.name',
                    }),
                    dataIndex: 'name',
                  },
                  {
                    title: intl.formatMessage({
                      id: 'views.products.table.quantity',
                    }),
                    dataIndex: 'total',
                  },
                ]}
                dataSource={data?.number_of_products ?? []}
                pagination={false}
                title={() => 'Số lượng sản phẩm'}
              />
            </Col>
            <Col span={8}>
              <Table
                rowKey="name"
                columns={[
                  {
                    title: intl.formatMessage({
                      id: 'views.gifts.table.name',
                    }),
                    dataIndex: 'name',
                  },
                  {
                    title: intl.formatMessage({
                      id: 'views.gifts.table.quantity',
                    }),
                    dataIndex: 'total',
                  },
                ]}
                dataSource={data?.number_of_gifts_given ?? []}
                pagination={false}
                title={() => 'Số lượng quà tặng đã tặng'}
              />
            </Col>
          </Row>
        </StyledSpace>
      </Spin>
    </ContentWrapper>
  );
}
