import _ from 'lodash';
import { FIELD } from 'constants/field';

export const calcTotalPrice = (data = []) => {
  return _.reduce(
    data,
    (result, { price, quantity }) => {
      return result + Number.parseFloat(price) * Number.parseInt(quantity ?? 0);
    },
    0,
  );
};

export const checkHasQuantity = (products) => {
  return _.some(products, (product) => (product?.quantity || 0) > 0);
};

export const normalizeSubmitData = (data) => {
  return {
    [FIELD.INVOICE]: {
      [FIELD.INVOICE_PRODUCTS]: _.map(
        data[FIELD.INVOICE_PRODUCTS],
        ({ code, ...item }) => ({ ...item }),
      ),
      [FIELD.INVOICE_GIFTS]: data[FIELD.INVOICE_GIFTS],
      [FIELD.TOTAL]: data[FIELD.TOTAL],
      [FIELD.CODE]: data[FIELD.CODE],
    },
    [FIELD.SURVEYS]: data[FIELD.SURVEYS],
    [FIELD.INVOICE_PHOTO]: data[FIELD.FILE_NAME],
    [FIELD.PG_NAME]: data[FIELD.PG_NAME],
    [FIELD.PG_PHONE_NUMBER]: data[FIELD.PG_PHONE_NUMBER],
    [FIELD.NAME]: data[FIELD.NAME],
    [FIELD.PHONE_NUMBER]: data[FIELD.PHONE_NUMBER],
    [FIELD.MARKET]: data[FIELD.MARKET],
  };
};
