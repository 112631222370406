import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Select, Form } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledSelect,
  StyledFormList,
  StyledSpace,
  StyledInputNumber,
  StyledCard,
  StyledButton,
} from 'styles/overrides';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import { useProvinces } from 'api/provinceApi';
import _ from 'lodash';
import { checkHasQuantity } from '../../app/utils';
import { formatInputNumber, parseInputNumber } from '../../../utils';
import { FormText } from '../../app/components/FormText';
import CustomerQuestionsForm from './CustomerQuestionsForm';
import { normalizeInitialValues, normalizeSubmitData } from '../utils';

export const CustomerForm = ({
  initialValues,
  onSubmit = () => {},
  isLoading = false,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { data: provinces = [], isLoading: isProvincesLoading } = useProvinces({
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  const mappingProvinces = useMemo(() => {
    return _.reduce(
      provinces,
      (mapping, currentProvince) => {
        mapping[currentProvince.id] = currentProvince;

        return mapping;
      },
      {},
    );
  }, [provinces]);

  const handleValuesChange = (changedValues) => {
    if (changedValues.province_id) {
      form.setFieldsValue({
        store_id: undefined,
      });
    }
  };

  const handleSubmit = (values) => {
    const data = normalizeSubmitData(values);

    onSubmit(data);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      onValuesChange={handleValuesChange}
      form={form}
      initialValues={normalizeInitialValues(initialValues)}
      onFinish={handleSubmit}
    >
      <Row gutter={[32, 32]}>
        <Col span={24} sm={8}>
          <StyledCard title="Thông tin" size="small">
            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.customers.form.label.fullName',
              })}
              name="name"
              rules={[{ required: true }]}
            >
              <StyledInput
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.fullName',
                })}
              />
            </StyledFormItem>

            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.customers.form.label.phone',
              })}
              name="phone"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: VNMobilePhonePattern,
                  message: intl.formatMessage({
                    id: 'validate.invalidPhoneNumber',
                  }),
                },
              ]}
            >
              <StyledInput
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.phone',
                })}
              />
            </StyledFormItem>

            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.customers.form.label.pgName',
              })}
              name="pg_name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledInput
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.pgName',
                })}
              />
            </StyledFormItem>

            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.customers.form.label.pgPhone',
              })}
              name="pg_phone"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: VNMobilePhonePattern,
                  message: intl.formatMessage({
                    id: 'validate.invalidPhoneNumber',
                  }),
                },
              ]}
            >
              <StyledInput
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.pgName',
                })}
              />
            </StyledFormItem>

            <StyledFormItem
              name="province_id"
              rules={[{ required: true }]}
              label={intl.formatMessage({
                id: 'views.customers.form.label.provinceId',
              })}
            >
              <StyledSelect
                loading={isProvincesLoading}
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.provinceId',
                })}
              >
                {provinces.length > 0 &&
                  provinces.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </StyledSelect>
            </StyledFormItem>

            <StyledFormItem
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.province_id !== currentValues.province_id
              }
            >
              {({ getFieldValue }) => {
                const provinceId = getFieldValue('province_id');
                const stores =
                  _.get(mappingProvinces, [provinceId, 'stores']) ?? [];

                return (
                  <StyledFormItem
                    name="store_id"
                    rules={[{ required: true }]}
                    label={intl.formatMessage({
                      id: 'views.customers.form.label.storeId',
                    })}
                  >
                    <StyledSelect
                      placeholder={intl.formatMessage({
                        id: 'views.customers.form.placeholder.storeId',
                      })}
                    >
                      {stores.length > 0 &&
                        stores.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                    </StyledSelect>
                  </StyledFormItem>
                );
              }}
            </StyledFormItem>

            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.customers.form.label.invoiceValue',
              })}
              name="invoice_value"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledInputNumber
                placeholder={intl.formatMessage({
                  id: 'views.customers.form.placeholder.invoiceValue',
                })}
                min={0}
                formatter={formatInputNumber}
                parser={parseInputNumber}
              />
            </StyledFormItem>
          </StyledCard>
        </Col>

        <Col span={24} sm={8}>
          <StyledCard title="Sản phẩm" size="small">
            <StyledFormItem>
              <StyledFormList
                name="products"
                rules={[
                  {
                    validator: async (rule, products) => {
                      const hasProduct = checkHasQuantity(products);

                      if (!hasProduct) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'validate.invalidProductTotal',
                            }),
                          ),
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, operation, { errors }) => {
                  return (
                    <>
                      <StyledSpace direction="vertical" size={8}>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row key={key} align="middle">
                            <Col span={18}>
                              <StyledFormItem
                                {...restField}
                                name={[name, 'name']}
                                noStyle
                              >
                                <FormText style={{ color: 'var(--black)' }} />
                              </StyledFormItem>
                            </Col>

                            <Col span={6}>
                              <StyledFormItem
                                {...restField}
                                name={[name, 'quantity']}
                                style={{ marginBottom: 0 }}
                              >
                                <StyledInputNumber
                                  min={0}
                                  formatter={formatInputNumber}
                                  parser={parseInputNumber}
                                />
                              </StyledFormItem>
                            </Col>
                          </Row>
                        ))}
                      </StyledSpace>

                      <StyledFormItem noStyle>
                        <Form.ErrorList errors={errors} />
                      </StyledFormItem>
                    </>
                  );
                }}
              </StyledFormList>
            </StyledFormItem>
          </StyledCard>

          <StyledCard title="Quà tặng" size="small">
            <StyledFormItem style={{ marginBottom: 50 }}>
              <StyledFormList
                name="gifts"
                rules={[
                  {
                    validator: async (rule, products) => {
                      const giftTotal = _.reduce(
                        products,
                        (
                          total,
                          { request_invoice_value = 0, quantity = 0 },
                        ) => {
                          return total + request_invoice_value * quantity;
                        },
                        0,
                      );

                      // if (giftTotal > currentData[FIELD.TOTAL]) {
                      //   return Promise.reject(
                      //     new Error(
                      //       intl.formatMessage({
                      //         id: 'validate.invalidGiftTotalWithTotal',
                      //       }),
                      //     ),
                      //   );
                      // }
                    },
                  },
                ]}
              >
                {(fields, operation, { errors }) => {
                  return (
                    <>
                      <StyledSpace direction="vertical" size={8}>
                        {fields.map(({ key, name, ...restField }) => {
                          return (
                            <Row key={key} align="middle">
                              <Col span={18}>
                                <StyledFormItem
                                  {...restField}
                                  name={[name, 'name']}
                                  noStyle
                                >
                                  <FormText style={{ color: 'var(--black)' }} />
                                </StyledFormItem>
                              </Col>

                              <Col span={6}>
                                <StyledFormItem
                                  {...restField}
                                  name={[name, 'quantity']}
                                  style={{ marginBottom: 0 }}
                                >
                                  <StyledInputNumber
                                    min={0}
                                    formatter={formatInputNumber}
                                    parser={parseInputNumber}
                                  />
                                </StyledFormItem>
                              </Col>
                            </Row>
                          );
                        })}
                      </StyledSpace>

                      <StyledFormItem noStyle>
                        <Form.ErrorList errors={errors} />
                      </StyledFormItem>
                    </>
                  );
                }}
              </StyledFormList>
            </StyledFormItem>
          </StyledCard>
        </Col>

        <Col span={24} sm={8}>
          <CustomerQuestionsForm />
        </Col>

        <Col span={24}>
          <Form.Item style={{ textAlign: 'center' }}>
            <StyledButton
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};
