/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'components/table';
import { StyledActions, StyledButton } from 'styles/overrides';
import { useGifts, useDeleteMultipleGifts } from 'api/giftApi';
import { Notification } from 'components/Notification';
import { GiftModal } from './GiftModal';
import { formatInputNumber } from 'utils/formatUtils';

export const GiftTable = () => {
  const intl = useIntl();
  const { data: gift, isLoading, refetch } = useGifts();
  const deleteMultipleGiftsMutation = useDeleteMultipleGifts();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'views.gifts.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.gifts.table.invoiceValue' }),
      dataIndex: 'request_invoice_value',
      render: (value) => formatInputNumber(value),
    },
    {
      title: intl.formatMessage({ id: 'common.table.index' }),
      dataIndex: 'index',
    },
  ];

  const handleMultipleDelete = () => {
    deleteMultipleGiftsMutation.mutate(
      { ids: selectedRowKeys },
      {
        onSuccess() {
          refetch();
          setSelectedRowKeys([]);
          setSelectedRows([]);
          Notification(
            'success',
            intl.formatMessage({ id: 'message.deleteSuccess' }),
          );
        },
        onError() {
          Notification(
            'error',
            intl.formatMessage({ id: 'message.commonError' }),
          );
        },
      },
    );
  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  return (
    <>
      <StyledActions size={24}>
        <StyledButton
          size="large"
          type="primary"
          onClick={() => setIsOpenModal(true)}
        >
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRowKeys.length === 0}
          onClick={handleMultipleDelete}
          type="danger"
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={gift?.data || []}
        loading={isLoading}
        pagination={false}
      />

      <GiftModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValue={selectedValue}
      />
    </>
  );
};
