/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'components/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { calcIndexByOrder, removeFalsyKeys } from 'utils';
import { StyledActions, StyledButton } from 'styles/overrides';
import { useStores } from 'api/storeApi';
import { StoreModal } from './StoreModal';
import { EnableStatus } from 'components/status';

export const StoreTable = () => {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data: customer, isLoading, refetch } = useStores({ params: search });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.limit,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.stores.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.stores.table.provinceName' }),
      dataIndex: ['province', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.active' }),
      dataIndex: 'active',
      render: (value) => <EnableStatus status={!!value} />,
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, limit }));
  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  return (
    <>
      <StyledActions size={24}>
        <StyledButton
          size="large"
          type="primary"
          onClick={() => setIsOpenModal(true)}
        >
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={customer?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.limit,
          total: customer?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />

      <StoreModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValue={selectedValue}
      />
    </>
  );
};
