import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const questionApi = {
  getAll(params) {
    return axiosClient
      .get('/admin/questions', { params })
      .then((response) => response?.data || []);
  },
  getById(id) {
    return axiosClient.get(`/admin/questions/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/questions', data);
  },
  update(id, data) {
    return axiosClient.patch(`/admin/questions/${id}`, data);
  },
  deleteMultiple(ids) {
    return axiosClient.delete('/admin/questions/delete/multiple', {
      data: { ids },
    });
  },
};

export const useQuestions = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['questions', 'list', params],
    queryFn: () => questionApi.getAll(params),
    ...options,
  });
};

export const useCreateQuestion = () => {
  return useMutation(questionApi.create);
};

export const useUpdateQuestion = (id) => {
  return useMutation((data) => questionApi.update(id, data));
};

export const useDeleteMultipleQuestions = () => {
  return useMutation(questionApi.deleteMultiple);
};
