/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Table } from 'components/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { calcIndexByOrder, removeFalsyKeys, formatInputNumber } from 'utils';
import { StyledButton, StyledTypographyParagraph } from 'styles/overrides';
import { useCustomers } from 'api/customerApi';
import { CustomerFilterSearchForm } from './CustomerFilterSearchForm';

export const CustomerTable = () => {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const {
    data: customer,
    isLoading,
    // refetch,
  } = useCustomers({ params: search });
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.limit,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.fullName' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.phone' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.invoiceValue' }),
      dataIndex: 'invoice_value',
      render: (value) => formatInputNumber(value),
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.products' }),
      dataIndex: 'products',
      render: (value) => {
        return value.map((item) => (
          <StyledTypographyParagraph
            key={item.id}
            style={{ whiteSpace: 'nowrap' }}
          >
            {item.name} - {item.quantity}
          </StyledTypographyParagraph>
        ));
      },
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.gifts' }),
      dataIndex: 'gifts',
      render: (value) => {
        return value.map((item) => (
          <StyledTypographyParagraph
            key={item.id}
            style={{ whiteSpace: 'nowrap' }}
          >
            {item.name} - {item.quantity}
          </StyledTypographyParagraph>
        ));
      },
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.provinceName' }),
      dataIndex: ['store', 'province', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.storeName' }),
      dataIndex: ['store', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'views.customers.table.pg' }),
      children: [
        {
          title: intl.formatMessage({ id: 'views.customers.table.pgName' }),
          dataIndex: 'pg_name',
        },
        {
          title: intl.formatMessage({ id: 'views.customers.table.pgPhone' }),
          dataIndex: 'pg_phone',
        },
      ],
    },
    {
      dataIndex: ['id'],
      render: (value) => (
        <Link to={`${value}`}>
          <StyledButton size="large">
            {intl.formatMessage({ id: 'common.btn.edit' })}
          </StyledButton>
        </Link>
      ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, limit }));
  };

  return (
    <>
      <CustomerFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Table
        rowKey="id"
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: (newSelectedRowKeys, newSelectedRows) => {
        //     setSelectedRowKeys(newSelectedRowKeys);
        //     setSelectedRows(newSelectedRows);
        //   },
        //   preserveSelectedRowKeys: true,
        // }}
        columns={columns}
        dataSource={customer?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.limit,
          total: customer?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
};
