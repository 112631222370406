import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem } from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { StoreSelector, ProvinceSelector } from 'components/selectors';

export const DashboardFilterSearchForm = ({ onSubmit, initialValues }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        // page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        store_id: null,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        store_id: null,
      });
    }
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      onValuesChange={handleValuesChange}
      formInstance={form}
    >
      <StyledSpace size={16}>
        <StyledFormItem name="province_id">
          <ProvinceSelector
            placeholder={intl.formatMessage({
              id: 'common.placeholder.provinceId',
            })}
            $customType="filter"
            allowClear
            onClear={() => handleClear('province_id')}
          />
        </StyledFormItem>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.province_id !== currentValues.province_id
          }
        >
          {({ getFieldValue }) => {
            const provinceId = getFieldValue('province_id');

            return (
              <StyledFormItem name="store_id">
                <StoreSelector provinceId={provinceId} allowClear />
              </StyledFormItem>
            );
          }}
        </StyledFormItem>

        <StyledFormItem name="from">
          <DatePicker
            placeholder={intl.formatMessage({
              id: 'common.placeholder.fromDate',
            })}
            $customType="filter"
            format="DD/MM/YYYY"
          />
        </StyledFormItem>

        <StyledFormItem name="to">
          <DatePicker
            placeholder={intl.formatMessage({
              id: 'common.placeholder.toDate',
            })}
            $customType="filter"
            format="DD/MM/YYYY"
          />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
};
