import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { StyledSelect } from 'styles/overrides';
import { useShops } from 'api/shopApi';

export const ShopSelector = ({ onChange, value, ...restProps }) => {
  const intl = useIntl();
  const { data, isLoading } = useShops();

  return (
    <StyledSelect
      placeholder={intl.formatMessage({ id: 'common.placeholder.shopId' })}
      {...restProps}
      loading={isLoading}
      onChange={onChange}
      value={value}
    >
      {data?.items?.length > 0 &&
        data?.items?.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
    </StyledSelect>
  );
};
