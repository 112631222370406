import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { AppHeader } from './components';

const StyledAppLayout = styled.div`
  max-width: 600px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
`;

export default function AppLayout() {
  return (
    <StyledAppLayout>
      <AppHeader />

      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Outlet />
      </div>
    </StyledAppLayout>
  );
}
