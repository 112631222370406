import _ from 'lodash';

export const normalizeInitialValues = (initialValue) => {
  if (!initialValue) return null;

  const {
    gifts,
    products,
    invoice_value,
    name,
    pg_name,
    pg_phone,
    phone,
    store,
    questions,
  } = initialValue;

  const convertedQuestions = _.reduce(
    questions,
    (obj, { id, answers }) => {
      obj[id] = _.reduce(
        answers,
        (arr, item) => {
          if (!!item.choice) {
            arr.push(item.id.toString());
          }

          return arr;
        },
        [],
      );

      return obj;
    },
    {},
  );

  return {
    gifts,
    products,
    invoice_value,
    name,
    pg_name,
    pg_phone,
    phone,
    province_id: store?.province?.id,
    store_id: store?.id,
    questions: convertedQuestions,
  };
};

export const normalizeSubmitData = (values) => {
  const { province_id, products, gifts, questions, ...restValues } = values;

  return {
    ...restValues,
    products: _.map(products, ({ id, quantity }) => ({ id, quantity })),
    gifts: _.map(gifts, ({ id, quantity }) => ({ id, quantity })),
    questions: _.map(questions, (value, key) => {
      return {
        id: key,
        answers: _.map(value, (item) => ({ id: item })),
      };
    }),
  };
};
