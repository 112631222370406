import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { StyledButton } from 'styles/overrides';
import { getCurrentStatus, doUpdateData } from 'store/slices/appSlice';

export const BackButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentStatus = useSelector(getCurrentStatus);

  const handleClickBack = () => {
    switch (currentStatus) {
      case APP_STATUS.LOCATION: {
        dispatch(
          doUpdateData({
            redirect: APP_STATUS.LOGIN,
            holdKeys: [FIELD.PG_NAME, FIELD.PG_PHONE_NUMBER],
          }),
        );

        return;
      }

      case APP_STATUS.ORDER_INFO: {
        dispatch(
          doUpdateData({
            redirect: APP_STATUS.LOCATION,
            holdKeys: [
              FIELD.PG_NAME,
              FIELD.PG_PHONE_NUMBER,
              FIELD.PROVINCE_ID,
              FIELD.STORE_ID,
            ],
          }),
        );

        return;
      }

      case APP_STATUS.CHOOSE_GIFTS: {
        dispatch(
          doUpdateData({
            redirect: APP_STATUS.ORDER_INFO,
            holdKeys: [
              FIELD.PG_NAME,
              FIELD.PG_PHONE_NUMBER,
              FIELD.PROVINCE_ID,
              FIELD.STORE_ID,
              FIELD.NAME,
              FIELD.PHONE_NUMBER,
              FIELD.TOTAL,
              FIELD.PRODUCTS,
            ],
          }),
        );

        return;
      }

      default: {
        return;
      }
    }
  };

  return (
    <StyledButton type="app" onClick={handleClickBack}>
      {intl.formatMessage({ id: 'common.btn.back' })}
    </StyledButton>
  );
};
