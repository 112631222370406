import { useIntl } from 'react-intl';
import { useQuestions } from 'api/questionApi';
import { Checkbox, Row, Col, Spin } from 'antd';
import { StyledCard, StyledFormItem } from 'styles/overrides';
import _ from 'lodash';

export default function CustomerQuestionsForm() {
  const intl = useIntl();
  const { data, isLoading } = useQuestions();

  return (
    <Spin spinning={isLoading}>
      <StyledCard title="Câu hỏi khảo sát" size="small">
        {_.map(data, ({ id, name, answers }) => (
          <StyledFormItem
            key={id}
            name={['questions', id.toString()]}
            label={name}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'validate.required' }),
              },
            ]}
          >
            <Checkbox.Group>
              <Row>
                {_.map(answers, (answer) => (
                  <Col span={24} key={answer.id}>
                    <Checkbox value={answer.id.toString()}>
                      {answer.name}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </StyledFormItem>
        ))}
      </StyledCard>
    </Spin>
  );
}
