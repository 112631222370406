import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute } from 'components';
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import Login from './views/auth/Login';
import Dashboard from './views/dashboard/Dashboard';
import Customers from './views/customers/Customers';
import { NotFound } from './components/layout/NotFound';
import { Permission } from 'components/permission/Permission';
import AppView from './views/app/AppView';
import AppLayout from './layout/AppLayout';

import Products from './views/products/Products';
import Gifts from './views/gifts/Gifts';
import Provinces from './views/provinces/Provinces';
import Stores from './views/stores/Stores';
import Questions from './views/questions/Questions';
import Answers from './views/answers/Answers';
import ViewCustomer from './views/customers/ViewCustomer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route element={<AppLayout />}>
                <Route path={PATH_NAME.ROOT} element={<AppView />} />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path={PATH_NAME.ADMIN} element={<DashboardLayout />}>
                  <Route
                    index
                    element={
                      <Permission
                        roles={[ROLE.VIEWER]}
                        noAccess={<Navigate to={PATH_NAME.DASHBOARD} />}
                      >
                        <Navigate to={PATH_NAME.ORDERS} />
                      </Permission>
                    }
                  />

                  <Route path={PATH_NAME.DASHBOARD}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Dashboard />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.CUSTOMERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Customers />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewCustomer />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.PRODUCTS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Products />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.GIFTS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Gifts />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.PROVINCES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Provinces />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.STORES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Stores />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.QUESTIONS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Questions />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.ANSWERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Answers />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>

              <Route element={<AuthLayout />}>
                <Route path={PATH_NAME.LOGIN} element={<Login />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
