import { StyledTypographyParagraph } from 'styles/overrides';

export const FormText = ({ value, prefixString, ...props }) => {
  return (
    <StyledTypographyParagraph style={{ color: 'var(--white)' }} {...props}>
      {prefixString || ''}
      {value}
    </StyledTypographyParagraph>
  );
};
