import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { LOCALE } from 'constants/common';
import { APP_STATUS } from 'constants/status';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    currentLocale: LOCALE.VIETNAMESE,
    status: APP_STATUS.LOGIN,
    data: {},
  },
  reducers: {
    doSwitchLocale(state, action) {
      state.currentLocale = action.payload;
    },
    doUpdateData(state, { payload: { redirect, data = {}, holdKeys = [] } }) {
      if (holdKeys?.length > 0) {
        state.data = _.pick(state.data, holdKeys);
      } else {
        state.data = { ...state.data, ...data };
      }

      state.status = redirect;
    },
  },
});

export const getCurrentStatus = (state) => state.app.status;
export const getCurrentData = (state) => state.app.data;

const { actions, reducer } = appSlice;
export const { doSwitchLocale, doUpdateData } = actions;
export default reducer;
