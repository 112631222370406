import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const giftApi = {
  getAll(params) {
    return axiosClient.get('/gifts', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/gifts/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/gifts', data);
  },
  update({ id, ...data }) {
    return axiosClient.patch(`/admin/gifts/${id}`, data);
  },
  delete(id) {
    return axiosClient.delete(`/admin/gifts/${id}`);
  },
  deleteMultiple(data) {
    return axiosClient.delete('/admin/gifts/delete/multiple', { data });
  },
};

export const useGifts = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['gifts', 'list', params],
    queryFn: () => giftApi.getAll(params),
    ...options,
  });
};

export const useCreateGift = () => {
  return useMutation(giftApi.create);
};

export const useUpdateGift = () => {
  return useMutation(giftApi.update);
};

export const useDeleteMultipleGifts = () => {
  return useMutation(giftApi.deleteMultiple);
};
