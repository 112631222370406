import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { AppWrapper } from 'layout/components';
import { StyledFormItem, StyledInput, StyledButton } from 'styles/overrides';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import { doUpdateData } from 'store/slices/appSlice';
import { getCurrentData } from 'store/slices/appSlice';

export const LoginForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentData = useSelector(getCurrentData);

  const handleSubmit = (values) => {
    dispatch(doUpdateData({ data: values, redirect: APP_STATUS.LOCATION }));
  };

  return (
    <AppWrapper>
      <Form
        initialValues={{
          [FIELD.PG_NAME]: currentData[FIELD.PG_NAME],
          [FIELD.PG_PHONE_NUMBER]: currentData[FIELD.PG_PHONE_NUMBER],
        }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <StyledFormItem
          name={FIELD.PG_NAME}
          label={intl.formatMessage({ id: 'common.field.pgName' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'validate.required' }),
            },
          ]}
          style={{ marginBottom: 50 }}
          $customType="label"
        >
          <StyledInput $customType="app" />
        </StyledFormItem>

        <StyledFormItem
          name={FIELD.PG_PHONE_NUMBER}
          label={intl.formatMessage({ id: 'common.field.phone' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'validate.required' }),
            },
            {
              pattern: VNMobilePhonePattern,
              message: intl.formatMessage({
                id: 'validate.invalidPhoneNumber',
              }),
            },
          ]}
          $customType="label"
          style={{ marginBottom: 50 }}
        >
          <StyledInput $customType="app" />
        </StyledFormItem>

        <StyledFormItem style={{ marginBottom: 0, textAlign: 'center' }}>
          <StyledButton htmlType="submit" type="app">
            {intl.formatMessage({ id: 'common.btn.login' })}
          </StyledButton>
        </StyledFormItem>
      </Form>
    </AppWrapper>
  );
};
