import { useMemo } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import QuestionForm from '../QuestionForm';
import { useCreateQuestion, useUpdateQuestion } from 'api/questionApi';

export default function QuestionModal({ initialValue, onSuccess, ...props }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createMutation = useCreateQuestion();
  const updateMutation = useUpdateQuestion(initialValue?.id);
  const mutation = useMemo(() => {
    return initialValue ? updateMutation : createMutation;
  }, [initialValue, updateMutation, createMutation]);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const handleSubmitData = (values) => {
    mutation.mutate(values, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValue ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: () => {
        Notification(
          'error',
          intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValue ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form.validateFields().then(handleSubmitData);
      }}
      okButtonProps={{
        loading: mutation.isLoading,
      }}
      {...props}
    >
      <QuestionForm form={form} initialValues={initialValue} />
    </Modal>
  );
}
