import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const customerApi = {
  getAll(params) {
    return axiosClient.get('/admin/customers', { params });
  },
  getById(id) {
    return axiosClient
      .get(`/admin/customers/${id}`)
      .then((response) => response?.data ?? null);
  },
  create(data) {
    return axiosClient.post('/admin/customers', data);
  },
  update(id, data) {
    return axiosClient.patch(`/admin/customers/${id}`, data);
  },
  //
  deleteMultiple(data) {
    return axiosClient.get('/admin/customers', { data });
  },
  export(params) {
    return axiosClient.get('/admin/export/customers', { params });
  },

  createPublic(data) {
    return axiosClient.post('/customers/public', data);
  },
  getUser(phone) {
    return axiosClient.get(`/customers/phone/${phone}`);
  },
  // update({ id, ...data }) {
  //   return axiosClient.put(`/customers/${id}`, data);
  // },
  updatePublic({ id, ...data }) {
    return axiosClient.put(`/customers/public/${id}`, data);
  },
  import(data) {
    return axiosClient.post('/customers/import', data, {
      timeout: 0,
    });
  },
  checkOTP(data) {
    return axiosClient.post('/customers/otp', data);
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // });
  },
  getByPhone(phone) {
    return axiosClient.get(`/customers/phone/${phone}`);
  },
  exportExcel(params) {
    return axiosClient.get('/customers/excel/export', {
      params,
      responseType: 'blob',
    });
  },
};

export const useCustomers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['customers', 'list', params],
    queryFn: () => customerApi.getAll(params),
    ...options,
  });
};

export const useCustomer = ({ id } = {}) => {
  return useQuery({
    queryKey: ['customers', 'details', id],
    queryFn: () => customerApi.getById(id),
  });
};

export const useCreateCustomer = () => {
  return useMutation(customerApi.create);
};

export const useUpdateCustomer = (id) => {
  return useMutation((data) => customerApi.update(id, data));
};

///

export const useCreatePublicCustomer = () => {
  return useMutation(customerApi.createPublic);
};
export const useGetCustomers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['phoneCustomers', params],
    queryFn: () => customerApi.getUser(params),
    ...options,
  });
};

export const useUpdatePublicCustomer = () => {
  return useMutation(customerApi.updatePublic);
};

export const useImportCustomer = () => {
  return useMutation(customerApi.import);
};

export const useCheckOTP = () => {
  return useMutation(customerApi.checkOTP);
};

export const useGetCustomerByPhone = () => {
  return useMutation(customerApi.getByPhone);
};

export const useExportExcelCustomers = ({ page, limit, ...restParams }) => {
  return useMutation(() => customerApi.exportExcel(restParams));
};

export const useExportCustomers = (params) => {
  return useMutation(() => customerApi.export(params));
};
