import { useMutation, useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const appApi = {
  getProvinces(params) {
    return axiosClient.get('/provinces', { params });
  },
  getProducts(params) {
    return axiosClient
      .get('/products', { params })
      .then((response) => response?.data || []);
  },
  getGifts(params) {
    return axiosClient
      .get('/gifts', { params })
      .then((response) => response?.data || []);
  },
  createCustomer(data) {
    return axiosClient.post('/customers', data);
  },
  getQuestions(params) {
    return axiosClient
      .get('/questions', { params })
      .then((response) => response?.data || []);
  },
};

export const useProvinces = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['app', 'locations', 'list', params],
    queryFn: () => appApi.getProvinces(params),
    ...options,
  });
};

export const useAppProducts = ({ params } = {}) => {
  return useQuery({
    queryKey: ['app', 'products', 'list', params],
    queryFn: () => appApi.getProducts(params),
  });
};

export const useAppGifts = ({ params } = {}) => {
  return useQuery({
    queryKey: ['app', 'gifts', 'list', params],
    queryFn: () => appApi.getGifts(params),
  });
};

export const useCreateCustomer = () => {
  return useMutation(appApi.createCustomer);
};

export const useAppQuestions = ({ params } = {}) => {
  return useQuery({
    queryKey: ['app', 'questions', 'list', params],
    queryFn: () => appApi.getQuestions(params),
  });
};
