import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, Row, Col, Spin } from 'antd';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import { formatInputNumber, parseInputNumber } from 'utils/formatUtils';
import { FormText } from '../components/FormText';
import { AppWrapper } from 'layout/components';
import {
  StyledFormItem,
  StyledSpace,
  StyledButton,
  StyledFormList,
  StyledInputNumber,
  StyledInput,
  StyledTypographyParagraph,
  StyledAppLabel,
} from 'styles/overrides';
import { doUpdateData, getCurrentData } from 'store/slices/appSlice';
import { useAppProducts } from 'api/appApi';
import { BackButton } from './BackButton';
import { checkHasQuantity } from '../utils';

export const OrderInfoForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentData = useSelector(getCurrentData);
  const { data: products = [], isLoading } = useAppProducts();

  const initialProducts = useMemo(() => {
    if (!currentData[FIELD.PRODUCTS]) {
      return products;
    }

    const productsMapping = _.reduce(
      currentData[FIELD.PRODUCTS],
      (result, { id, ...restCurrentProduct }) => {
        result[id] = restCurrentProduct;

        return result;
      },
      {},
    );

    return _.map(products, (item) => ({
      ...item,
      ...(productsMapping[item.id] ?? {}),
    }));
  }, [products, currentData]);

  const handleSubmit = (values) => {
    const data = {
      ...values,
      [FIELD.PRODUCTS]: _.map(values[FIELD.PRODUCTS], ({ id, quantity }) => ({
        id,
        quantity,
      })),
    };

    dispatch(
      doUpdateData({
        data,
        redirect: APP_STATUS.CHOOSE_GIFTS,
      }),
    );
  };

  return (
    <AppWrapper>
      <StyledAppLabel>
        {intl.formatMessage({ id: 'views.app.orderInfoTitle' })}
      </StyledAppLabel>

      {isLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            [FIELD.NAME]: currentData[FIELD.NAME],
            [FIELD.PHONE_NUMBER]: currentData[FIELD.PHONE_NUMBER],
            [FIELD.TOTAL]: currentData[FIELD.TOTAL],
            [FIELD.PRODUCTS]: initialProducts,
          }}
        >
          <StyledFormItem
            name={FIELD.NAME}
            label={intl.formatMessage({ id: 'common.field.customerName' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'validate.required' }),
              },
            ]}
            $customType="label"
            style={{ marginBottom: 40 }}
          >
            <StyledInput $customType="app" />
          </StyledFormItem>

          <StyledFormItem
            name={FIELD.PHONE_NUMBER}
            label={intl.formatMessage({ id: 'common.field.phone' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'validate.required' }),
              },
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
            $customType="label"
          >
            <StyledInput $customType="app" />
          </StyledFormItem>

          <StyledFormItem>
            <Row align="middle">
              <Col span={14}>
                <StyledTypographyParagraph
                  style={{
                    fontSize: 16,
                    color: 'var(--white)',
                    fontWeight: 700,
                  }}
                >
                  {intl.formatMessage({
                    id: 'common.field.total',
                  })}
                </StyledTypographyParagraph>
              </Col>

              <Col span={10}>
                <StyledFormItem
                  name={FIELD.TOTAL}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'validate.required' }),
                    },
                  ]}
                >
                  <StyledInputNumber
                    formatter={formatInputNumber}
                    parser={parseInputNumber}
                    $customType="app"
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </StyledFormItem>

          <StyledFormItem style={{ marginBottom: 50 }}>
            <StyledFormList
              name={FIELD.PRODUCTS}
              rules={[
                {
                  validator: async (rule, products) => {
                    const hasProduct = checkHasQuantity(products);

                    if (!hasProduct) {
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'validate.invalidProductTotal',
                          }),
                        ),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, operation, { errors }) => {
                return (
                  <>
                    <StyledSpace direction="vertical" size={8}>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} align="middle">
                          <Col span={18}>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              noStyle
                            >
                              <FormText prefixString={`${index + 1}. `} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, 'quantity']}
                              style={{ marginBottom: 0 }}
                            >
                              <StyledInputNumber
                                min={0}
                                formatter={formatInputNumber}
                                parser={parseInputNumber}
                                $customType="app"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </StyledSpace>

                    <StyledFormItem noStyle>
                      <Form.ErrorList errors={errors} />
                    </StyledFormItem>
                  </>
                );
              }}
            </StyledFormList>
          </StyledFormItem>

          <StyledFormItem style={{ marginBottom: 0 }}>
            <StyledSpace style={{ justifyContent: 'space-between' }}>
              <BackButton />

              <StyledButton htmlType="submit" type="app">
                {intl.formatMessage({ id: 'common.btn.confirm' })}
              </StyledButton>
            </StyledSpace>
          </StyledFormItem>
        </Form>
      )}
    </AppWrapper>
  );
};
