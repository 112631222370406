import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const answerApi = {
  getAll(params) {
    return axiosClient
      .get('/admin/answers', { params })
      .then((response) => response?.data || []);
  },
  getById(id) {
    return axiosClient.get(`/admin/answers/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/answers', data);
  },
  update(id, data) {
    return axiosClient.patch(`/admin/answers/${id}`, data);
  },
  deleteMultiple(ids) {
    return axiosClient.delete('/admin/answers/delete/multiple', {
      data: { ids },
    });
  },
};

export const useAnswers = ({ params } = {}) => {
  return useQuery({
    queryKey: ['answers', 'list', params],
    queryFn: () => answerApi.getAll(params),
  });
};

export const useCreateAnswer = () => {
  return useMutation(answerApi.create);
};

export const useUpdateAnswer = (id) => {
  return useMutation((data) => answerApi.update(id, data));
};

export const useDeleteMultipleAnswers = () => {
  return useMutation(answerApi.deleteMultiple);
};
