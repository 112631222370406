import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, Row, Col, Spin } from 'antd';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { formatInputNumber, parseInputNumber } from 'utils/formatUtils';
import { FormText } from './FormText';
import {
  StyledFormItem,
  StyledSpace,
  StyledButton,
  StyledFormList,
  StyledInputNumber,
  StyledAppLabel,
} from 'styles/overrides';
// import { checkHasQuantity } from '../utils';
import { doUpdateData, getCurrentData } from 'store/slices/appSlice';
import { useAppGifts } from 'api/appApi';
import { AppWrapper } from 'layout/components';
import { BackButton } from './BackButton';

export const ChooseGifts = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentData = useSelector(getCurrentData);
  const { data: gifts = [], isLoading: isLoadingGifts } = useAppGifts();

  const initialGifts = useMemo(() => {
    if (!currentData[FIELD.GIFTS]) {
      return gifts;
    }

    const giftsMapping = _.reduce(
      currentData[FIELD.GIFTS],
      (result, { id, ...restCurrentProduct }) => {
        result[id] = restCurrentProduct;

        return result;
      },
      {},
    );

    return _.map(gifts, (item) => ({
      ...item,
      ...(giftsMapping[item.id] ?? {}),
    }));
  }, [gifts, currentData]);

  const handleSubmit = (values) => {
    const data = {
      [FIELD.GIFTS]: _.map(values[FIELD.GIFTS], ({ id, quantity }) => ({
        id,
        quantity,
      })),
    };

    dispatch(
      doUpdateData({
        data,
        redirect: APP_STATUS.QUESTIONS,
      }),
    );
  };

  return (
    <AppWrapper>
      <StyledAppLabel type="outline">
        {intl.formatMessage({ id: 'views.app.chooseGiftTitle' })}
      </StyledAppLabel>

      {isLoadingGifts ? (
        <Spin />
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            [FIELD.GIFTS]: initialGifts,
          }}
        >
          <StyledFormItem style={{ marginBottom: 50 }}>
            <StyledFormList
              name={FIELD.GIFTS}
              rules={[
                {
                  validator: async (rule, products) => {
                    // const hasProduct = checkHasQuantity(products);

                    // if (!hasProduct) {
                    //   return Promise.reject(
                    //     new Error(
                    //       intl.formatMessage({
                    //         id: 'validate.invalidProductTotal',
                    //       }),
                    //     ),
                    //   );
                    // }

                    const giftTotal = _.reduce(
                      products,
                      (total, { request_invoice_value = 0, quantity = 0 }) => {
                        return total + request_invoice_value * quantity;
                      },
                      0,
                    );

                    if (giftTotal > currentData[FIELD.TOTAL]) {
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'validate.invalidGiftTotalWithTotal',
                          }),
                        ),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, operation, { errors }) => {
                return (
                  <>
                    <StyledSpace direction="vertical" size={8}>
                      {fields.map(({ key, name, ...restField }) => {
                        const prefixString = `- Bill ${formatInputNumber(
                          form.getFieldValue(FIELD.GIFTS)?.[name]
                            ?.request_invoice_value,
                        )} - `;

                        return (
                          <Row key={key} align="middle">
                            <Col span={18}>
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                noStyle
                              >
                                <FormText prefixString={prefixString} />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                {...restField}
                                name={[name, 'quantity']}
                                style={{ marginBottom: 0 }}
                              >
                                <StyledInputNumber
                                  min={0}
                                  formatter={formatInputNumber}
                                  parser={parseInputNumber}
                                  $customType="app"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </StyledSpace>

                    <StyledFormItem noStyle>
                      <Form.ErrorList errors={errors} />
                    </StyledFormItem>
                  </>
                );
              }}
            </StyledFormList>
          </StyledFormItem>

          <StyledFormItem style={{ marginBottom: 0 }}>
            <StyledSpace style={{ justifyContent: 'space-between' }}>
              <BackButton />

              <StyledButton htmlType="submit" type="app">
                {intl.formatMessage({ id: 'common.btn.confirm' })}
              </StyledButton>
            </StyledSpace>
          </StyledFormItem>
        </Form>
      )}
    </AppWrapper>
  );
};
