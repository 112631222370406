import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form, Spin, message } from 'antd';
import { APP_STATUS } from 'constants/status';
import { FIELD } from 'constants/field';
import { AppWrapper } from 'layout/components';
import {
  StyledFormItem,
  StyledSpace,
  StyledButton,
  StyledAppLabel,
  StyledCheckBoxGroup,
  StyledCheckBox,
  StyledTypographyParagraph,
} from 'styles/overrides';
import { doUpdateData, getCurrentData } from 'store/slices/appSlice';
import { useAppQuestions, useCreateCustomer } from 'api/appApi';

export default function QuestionForm() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentData = useSelector(getCurrentData);
  const createCustomerMutation = useCreateCustomer();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { data: questions = [], isLoading: isLoadingQuestions } =
    useAppQuestions();

  const currentQuestion = useMemo(() => {
    return questions?.[currentQuestionIndex];
  }, [currentQuestionIndex, questions]);

  const isDone = currentQuestionIndex >= questions.length - 1;

  const handlePrev = () => {
    if (currentQuestionIndex === 0) {
      dispatch(doUpdateData({ redirect: APP_STATUS.CHOOSE_GIFTS }));
    } else {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    form.validateFields().then(() => {
      if (isDone) {
        const questions = _.map(
          _.get(form.getFieldsValue(true), [FIELD.QUESTIONS]),
          (value, key) => {
            return {
              id: key,
              answers: _.map(value, (item) => ({ id: item })),
            };
          },
        );

        const data = {
          ..._.omit(currentData, [FIELD.PROVINCE_ID]),
          [FIELD.GIFTS]: _.map(
            currentData[FIELD.GIFTS],
            ({ id, quantity }) => ({
              id,
              quantity: quantity ?? 0,
            }),
          ),
          [FIELD.PRODUCTS]: _.map(
            currentData[FIELD.PRODUCTS],
            ({ id, quantity }) => ({
              id,
              quantity: quantity ?? 0,
            }),
          ),
          [FIELD.QUESTIONS]: questions,
        };

        createCustomerMutation.mutate(data, {
          onSuccess() {
            message.success(
              intl.formatMessage({ id: 'message.commonSuccess' }),
            );
            dispatch(
              doUpdateData({
                redirect: APP_STATUS.ORDER_INFO,
                holdKeys: [
                  FIELD.PG_NAME,
                  FIELD.PG_PHONE_NUMBER,
                  FIELD.PROVINCE_ID,
                  FIELD.STORE_ID,
                ],
              }),
            );
          },
          onError() {
            message.error(intl.formatMessage({ id: 'message.commonError' }));
          },
        });
      } else {
        setCurrentQuestionIndex((prev) => prev + 1);
      }
    });
  };

  if (isLoadingQuestions) {
    return <Spin />;
  }

  return (
    <AppWrapper>
      {currentData ? (
        <>
          <StyledAppLabel type="outline">
            {_.get(currentQuestion, ['name'])}
          </StyledAppLabel>

          <Form form={form} preserve initialValues={{}}>
            <StyledFormItem
              name={[
                FIELD.QUESTIONS,
                _.get(currentQuestion, ['id'])?.toString(),
              ]}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'validate.required' }),
                },
              ]}
            >
              <StyledCheckBoxGroup>
                <StyledSpace direction="vertical">
                  {_.map(_.get(currentQuestion, ['answers']), (item) => (
                    <StyledCheckBox value={item.id.toString()} key={item.id}>
                      <StyledButton type="outline" block>
                        {item.name}
                      </StyledButton>
                    </StyledCheckBox>
                  ))}
                </StyledSpace>
              </StyledCheckBoxGroup>
            </StyledFormItem>

            <StyledFormItem style={{ marginBottom: 0 }}>
              <StyledSpace style={{ justifyContent: 'space-between' }}>
                <StyledButton type="app" onClick={handlePrev}>
                  {intl.formatMessage({ id: 'common.btn.back' })}
                </StyledButton>

                <StyledButton
                  type="app"
                  onClick={handleNext}
                  loading={createCustomerMutation.isLoading}
                >
                  {intl.formatMessage({
                    id: `common.btn.${isDone ? 'done' : 'confirm'}`,
                  })}
                </StyledButton>
              </StyledSpace>
            </StyledFormItem>
          </Form>
        </>
      ) : (
        <StyledTypographyParagraph
          style={{
            fontSize: 16,
            color: 'var(--white)',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: 'message.noData',
          })}
        </StyledTypographyParagraph>
      )}
    </AppWrapper>
  );
}
