import { useMemo } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import { StoreForm } from './StoreForm';
import { useCreateStore, useUpdateStore } from 'api/storeApi';

const normalizeInitialValues = (initialValue) => {
  if (!initialValue) return null;

  return {
    ...initialValue,
    active: !!initialValue.active,
    province_id: initialValue?.province?.id,
  };
};

export const StoreModal = ({ initialValue, onSuccess, ...props }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createStoreMutation = useCreateStore();
  const updateStoreMutation = useUpdateStore();
  const mutation = useMemo(() => {
    return initialValue ? updateStoreMutation : createStoreMutation;
  }, [initialValue, updateStoreMutation, createStoreMutation]);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const handleSubmitData = (values) => {
    const data = { ...values, active: +values.active };
    if (initialValue?.id) {
      data.id = initialValue.id;
    }

    mutation.mutate(data, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValue ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: (error) => {
        Notification(
          'error',
          error?.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValue ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form.validateFields().then(handleSubmitData);
      }}
      okButtonProps={{
        loading: mutation.isLoading,
      }}
      {...props}
    >
      <StoreForm
        form={form}
        initialValues={normalizeInitialValues(initialValue)}
      />
    </Modal>
  );
};
