import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const storeApi = {
  getAll(params) {
    return axiosClient.get('/admin/stores', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/stores/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/stores', data);
  },
  update({ id, ...data }) {
    return axiosClient.patch(`/admin/stores/${id}`, data);
  },
  deleteMultiple(data) {
    return axiosClient.delete('/admin/stores/delete/multiple', { data });
  },
};

export const useStores = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['stores', 'list', params],
    queryFn: () => storeApi.getAll(params),
    ...options,
  });
};

export const useCreateStore = () => {
  return useMutation(storeApi.create);
};

export const useUpdateStore = () => {
  return useMutation(storeApi.update);
};

export const useDeleteMultipleStores = () => {
  return useMutation(storeApi.deleteMultiple);
};
