import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const EnableStatus = ({ status = false }) => {
  const Icon = status ? CheckOutlined : CloseOutlined;

  return (
    <Icon
      style={{
        fontSize: 20,
        color: status ? 'var(--green600)' : 'var(--redText)',
      }}
    />
  );
};
