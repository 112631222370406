import { useMemo } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import { GiftForm } from './GiftForm';
import { useCreateGift, useUpdateGift } from 'api/giftApi';

export const GiftModal = ({ initialValue, onSuccess, ...props }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createGiftMutation = useCreateGift();
  const updateGiftMutation = useUpdateGift();
  const mutation = useMemo(() => {
    return initialValue ? updateGiftMutation : createGiftMutation;
  }, [initialValue, updateGiftMutation, createGiftMutation]);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const handleSubmitData = (values) => {
    const data = values;
    if (initialValue?.id) {
      data.id = initialValue.id;
    }

    mutation.mutate(values, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValue ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: () => {
        Notification(
          'error',
          intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValue ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form.validateFields().then(handleSubmitData);
      }}
      okButtonProps={{
        loading: mutation.isLoading,
      }}
      {...props}
    >
      <GiftForm form={form} initialValues={initialValue} />
    </Modal>
  );
};
