export const PATH_NAME = {
  ROOT: '/',
  ADMIN: '/admin',

  PRODUCTS: 'products',
  CUSTOMERS: 'customers',
  GIFTS: 'gifts',
  PROVINCES: 'provinces',
  STORES: 'stores',
  QUESTIONS: 'questions',
  ANSWERS: 'answers',

  CUSTOMER_ORDER: '/orders',
  LOGIN: '/admin/login',
  DASHBOARD: 'dashboard',
  ORDERS: 'customer-orders',
  USERS: 'users',
  LOGS: 'logs',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  USER: 'user',
  SHOPS: 'shops',
};

export const PATH_NAME_ACTION = {
  EDIT: 'edit',
};
