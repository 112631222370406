import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useCustomer, useUpdateCustomer } from 'api/customerApi';
import { Loading } from 'components/loading';
import { Notification } from 'components/Notification';
import { CustomerForm } from './components/CustomerForm';

export default function ViewCustomer() {
  const intl = useIntl();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useCustomer({ id });
  const updateCustomerMutation = useUpdateCustomer(id);

  const handleUpdate = (data) => {
    updateCustomerMutation.mutate(data, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: 'message.updateSuccess',
          }),
        );
      },
      onError: (error) => {
        Notification(
          'error',
          error?.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <CustomerForm
        initialValues={data}
        onSubmit={handleUpdate}
        isLoading={updateCustomerMutation.isLoading}
      />
    </div>
  );
}
