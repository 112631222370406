import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { StyledForm, StyledFormItem, StyledInput } from 'styles/overrides';

export const ProvinceForm = ({ initialValues, ...props }) => {
  const intl = useIntl();

  useEffect(() => {
    props.form?.setFieldsValue(initialValues);
  }, [initialValues, props.form]);

  return (
    <StyledForm layout="vertical" size="large" {...props}>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.provinces.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput
              placeholder={intl.formatMessage({
                id: 'views.provinces.form.placeholder.name',
              })}
            />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
};
